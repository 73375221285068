import { BaseDto } from "@/shared/dtos/base-dto";
import { ambito } from "./ambito";
import { enumeracion } from "./enumeracion";
import { kpis_actuacion } from "./kpis_actuacion";
import { tarea } from "./tarea";
import { Usuario } from "./usuario";

export class actuacion extends BaseDto {
  public codigo!: string;
  public nombre!: string;
  public descripcion!: string;
  public plazo_estimado!: number;
  public fecha_inicio!: Date;
  public fecha_fin!: Date;

  public inversion_prevista!: number;
  public inversion_realizada!: number;

  public id_responsable!: number;
  public id_ambito!: number;
  public id_prioridad!: number;
  public id_objetivo!: number;
  public id_actuacion!: number;

  public id_departamento!:number;
  
  /*Campos Ejecucion*/
  public plazo_ejecucion!: number;
  public fecha_inicio_ejecucion!: Date;
  public fecha_fin_ejecucion!: Date;


  public ejecucion_sobre_total!: number;

  public responsable!: Usuario;
  public actuaciones!: actuacion[];
  public tareas!: tarea[];
  public prioridad!: enumeracion;
  public ambito!: ambito;

  public kpis!: kpis_actuacion[];
  public progreso_kpis!: number;
  
  //public id_tipo_actuacon!: number;
  //public resultado_obtenido!: number;
  //public ejecucion_economica!: number;
  //public ejecucion_por_tareas!: number;
  //public resultado_previsto!: string;
  //public tipo_resultado_previsto!: number;
  //public valor_inicial!: number;
  //public valor_real!: number;

  //public tipo_actuacon!: enumeracion;
}
