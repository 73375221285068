























import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import tareaModule from "@/store/modules/tarea-module";
import { tarea } from "@/shared/dtos/tarea";
import usuarioModule from "@/store/modules/usuario-module";
import actuacionModule from "@/store/modules/actuacion-module";
import { UtilsString } from "@/utils/utils-string";

@Component({
  components: {
    UsuariosTareas: () => import("./usuariosTareas.vue"),
  },
})
export default class tareasFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: tarea = new tarea();

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await tareaModule.gettarea(this.getparamId().Parametro);
      this.item_pantalla = tareaModule.tarea;
    } else {
      this.item_pantalla.fecha_inicio = new Date();
      this.item_pantalla.fecha_fin_prevista = new Date();
      this.item_pantalla.fecha_fin = new Date();
      if (this.$route.query.actuacion !== undefined) {
        try {
          if (
            Number.parseInt(UtilsString.ValueOf(this.$route.query.actuacion)) >
            0
          ) {
            this.item_pantalla.id_actuacion = Number.parseInt(
              UtilsString.ValueOf(this.$route.query.actuacion)
            );
          }
        } catch (error) {}
      }
    }

    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    await usuarioModule.getusuarios();
    await actuacionModule.getactuaciones();
  }

  public CreateNameTabs() {
    this.AddTabName("Ficha");
    this.AddTabName("Usuarios");
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre"
    )
      .isRequired()
      .Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_inicio),
      "Fecha inicio",
      "fecha_inicio"
    )
      .isRequired()
      .Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_fin_prevista),
      "Fecha fin prevista",
      "fecha_fin_prevista"
    )
      .isRequired()
      .Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.porcen_ejecucion,
      "% de actuación",
      "porcen_ejecucion"
    ).Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.porcen_trabajo_total,
      "% trabajo hecho",
      "porcen_trabajo_total"
    ).Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.completada,
      "Completada",
      "completada",
      1,
      "Completada",
      "Completada"
    ).Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_responsable,
      "Responsable",
      "id_responsable"
    )
      .Dimesiones(12, 4, 4)
      .isRequired()
      .CreateComboSingle(usuarioModule.usuarios, "nombre", "id");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_actuacion,
      "Actuación",
      "id_actuacion"
    )
      .Dimesiones(12, 4, 4)
      .isRequired()
      .CreateComboSingle(actuacionModule.actuaciones, "codigo", "id");

    this.AddFormItemDataDefaultString(
      new Date(this.item_pantalla.fecha_fin).getDay() +
        "/" +
        (new Date(this.item_pantalla.fecha_fin).getMonth() + 1) +
        "/" +
        new Date(this.item_pantalla.fecha_fin).getFullYear(),
      "Fecha completada",
      "fecha_fin"
    )
      .isVisible(this.item_pantalla.completada === true)
      .isReadOnly()
      .Dimesiones(12, 4, 4);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion"
    ).CreateTextArea();

    this.AddFormItemDataDefaultSlot(
      this.item_pantalla.usuarios,
      "Usuarios",
      "usuarios",
      500,
      2
    )
      .Dimesiones(12, 12, 12)
      .isVisible(!this.EstoyCreado);
  }

  public Insertar(object: tarea) {
    tareaModule.guardartarea(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: tarea) {
    tareaModule.modificartarea(object).then(() => {
      this.AllSaveOk();
    });
  }
}
