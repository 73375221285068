import { store } from "@/store/store";
import { actuacion } from "@/shared/dtos/actuacion";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { ClassReportWizardForm } from "@/shared/dtos/ClassReportWizardForm";
import { FormDateDiff } from "@/shared/dtos/FormDateDiff";

@Module({
  namespaced: true,
  name: "actuacionModule",
  store,
  dynamic: true,
})
class actuacionModule extends VuexModule {
  public actuaciones: actuacion[] = [];
  public actuacion: actuacion = new actuacion();

  @Action({ commit: "onGetactuaciones" })
  public async getactuaciones() {
    return await ssmHttpService.get(API.actuacion);
  }

  @Action({ commit: "onGetactuacion" })
  public async getactuacion(id: any) {
    return await ssmHttpService.get(API.actuacion + "/" + id);
  }

  @Action
  public async guardaractuacion(actuacion: actuacion) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.actuacion, actuacion.toJson());
  }

  @Action
  public async modificaractuacion(actuacion: actuacion) {
    return await ssmHttpService.put(
      API.actuacion + "/" + actuacion.id,
      actuacion
    );
  }

  @Action
  public async eliminaractuacion(actuacion: actuacion) {
    return await ssmHttpService.delete(
      API.actuacion + "/" + actuacion.id,
      null,
      false
    );
  }
  @Action
  public async GenerarInformeActuaciones(report: ClassReportWizardForm) {
    return await ssmHttpService.post(
      API.actuacion + "/Report1/",
      report.toJson()
    );
  }
  @Action
  public async FormDateDiff(report: FormDateDiff) {
    return await ssmHttpService.post(
      API.actuacion + "/DateDif/",
      report.toJson(),false
    );
  }
  

  @Mutation
  public onGetactuaciones(res: actuacion[]) {
    this.actuaciones = res ? res.map((x) => new actuacion(x)) : [];
  }

  @Mutation
  public onGetactuacion(res: actuacion) {
    this.actuacion = new actuacion(res);
  }
}
export default getModule(actuacionModule);
