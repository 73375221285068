import { store } from '@/store/store';
import { tarea } from '@/shared/dtos/tarea';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'tareaModule',
    store,
    dynamic: true
})
class tareaModule extends VuexModule {
    public tareas: tarea[] = [];
    public tarea: tarea = new tarea();

    @Action({ commit: 'onGettareas' })
    public async gettareas() {
        return await ssmHttpService.get(API.tarea);
    }

    @Action({ commit: 'onGettarea' })
    public async gettarea(id: any) {
        return await ssmHttpService.get(API.tarea + '/' + id);
    }

    @Action
    public async guardartarea(tarea: tarea) {
       return await ssmHttpService.post(API.tarea, tarea.toJson());
    }

    @Action
    public async modificartarea(tarea: tarea) {
        return await ssmHttpService.put(API.tarea + '/' + tarea.id, tarea);
    }

    @Action
    public async eliminartarea(tarea: tarea) {
       return await ssmHttpService.delete(API.tarea + '/' + tarea.id, null, false);
    }

    @Mutation
    public onGettareas(res: tarea[]) {
        this.tareas = res ? res.map((x) => new tarea(x)) : []
    }

    @Mutation
    public onGettarea(res: tarea) {
        this.tarea = new tarea(res);
    }

}
export default getModule(tareaModule);