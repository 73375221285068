import { BaseDto } from "@/shared/dtos/base-dto";
import { actuacion } from "./actuacion";
import { Usuario } from "./usuario";
import { UsuarioAllDto } from "./usuariosAllDto";

export class tarea extends BaseDto {
  public nombre!: string;
  public descripcion!: string;
  public fecha_inicio!: Date;
  public fecha_fin!: Date;
  public fecha_fin_prevista!: Date;
  public porcen_ejecucion!: number;
  public porcen_trabajo_total!: number;
  public completada!: boolean;
  public id_actuacion!: number;
  public id_responsable!: number;
  public usuarios!:UsuarioAllDto[];
  public responsable!:Usuario;
  public actuacion!:actuacion;
}
